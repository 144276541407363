import React from 'react';
import styled, { css } from 'styled-components';

import { useAnalytics, useIntercomLauncher } from 'context/AnalyticsProvider';
import Button from 'components/forms/Button';

const Btn = styled(Button)`
	position: fixed;
	bottom: -2px;
	right: -2px;
	z-index: 300;
	box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.16);
	border-radius: 10px 0px 0px 0px;
	${p =>
		p.theme.media.smallOnly(css`
			padding: 9px 14px;
			line-height: 18px;
			font-size: 14px;
		`)}
`;

export default function CallBackButton() {
	const { ready } = useAnalytics();

	// Intercom launcher hook
	useIntercomLauncher({
		ready,
		hideChatButton: true,
	});

	if (!ready) return null;

	return (
		<Btn outlined="true" href="#ring-meg">
			Ring meg
		</Btn>
	);
}
