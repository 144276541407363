import React from 'react';
import styled, { css } from 'styled-components';

import Spacing from 'layouts/Spacing';
import MaxWidth from 'layouts/max-width';
import SlickSlider from 'components/slick-slider/SlickSlider';
import TitleAndText from 'parts/title-and-text/TitleAndText';
import Price, { getMonthlyPrice } from './Price';
import DefaultImage from 'images/placeholders/default-image-person.jpg';
import { BuyButton } from './Price';
import BlankButton from 'components/forms/BlankButton';
import IconArrowDown from 'images/icons/ArrowDown';

//#region Styling

const Wrap = styled.div`
	position: relative;
	height: 100%;
	> div[role='region'] {
		height: 100%;
		min-height: 150px;
	}
	.slide,
	.singular-image {
		width: 100%;

		.lazy-image {
			position: relative;
			width: 100%;
			padding-top: 62.5%; /* 16:10 aspect ratio */
			overflow: hidden;
			${p =>
				p.theme.media.large(css`
					min-height: 480px;
				`)}
			.lazy-image__main {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
				right: 0;
				margin: auto;
				height: auto;
				width: auto;
				max-height: 100%;
				max-width: 100%;
			}
		}
	}
`;

const MaxWidthWrap = styled(MaxWidth)`
	${p =>
		p.theme.media.medium(css`
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			flex-direction: row-reverse;
			margin: ${p => p.theme.spacing.desktop.small} auto
				${p => p.theme.spacing.desktop.small};
		`)}
`;

const ImageWrap = styled.div`
	text-align: center;
	position: relative;
	padding: 0;
	${p =>
		p.theme.media.medium(css`
			width: 50%;
			padding-left: 20px;
		`)}

	.slick-dots {
		li button:before {
			font-size: 10px;
			${p =>
				p.theme.media.medium(css`
					font-size: 12px;
				`)}
		}
	}
	.slick-slide {
		border-radius: ${p => p.theme.utils.borderRadius};
	}
`;

const TextWrap = styled.div`
	padding: 0;
	${p =>
		p.theme.media.smallOnly(css`
			margin-top: 20px;
			padding: 0;
		`)}
	${p =>
		p.theme.media.medium(css`
			width: 50%;
			padding-right: 20px;
		`)}
	.stick {
		margin-bottom: 10px;
	}
	.price-total {
		font-weight: 500;
		font-size: 24px;
		line-height: 31px;
		margin: 0 0 10px;
	}
	.price-monthly {
		margin-bottom: 45px;
		p {
			font-weight: 400;
			margin: 0;
		}
		button {
			text-decoration: none;
			border-bottom: 1px solid ${p => p.theme.colors.blue600};
			&:hover {
				border-bottom-color: ${p => p.theme.colors.blue800};
			}
			svg {
				margin-left: 4px;
			}
		}
	}
	button.stretch {
		max-width: 100%;
		width: 330px;
	}
	.help-text {
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		margin: 20px 0 0;
		color: ${p => p.theme.colors.grey700};
	}
`;

//#endregion

/**
 * Represents the hero section of a product
 * @param {object} props - The properties for the component
 * @param {object} props.product - The product data
 * @param {Array} props.sliderImages - The images for the slider
 * @returns {JSX.Element}
 */
export default function Hero({ product, ...props }) {
	// Set images to array to use in slider
	const sliderImages = [];
	if (!!product?.images?.length) {
		product?.images?.forEach((pic, i) => {
			sliderImages.push({
				url: pic?.file?.url,
				alt: pic?.title,
				caption: pic?.description,
				...pic,
			});
		});
	}

	return (
		<section className="product-hero">
			<Spacing {...props}>
				<MaxWidthWrap>
					<ImageWrap>
						<Wrap>
							<Image
								product={product}
								images={sliderImages}
								sliderThumbnails={false}
							/>
						</Wrap>
					</ImageWrap>
					<TextWrap>
						<TitleAndText
							stickTitle={product?.title}
							title={product?.smallTitle}
							headinglevel="h1"
							isfirstheading={true}
							spacing={{ bottom: 'none' }}
							componentindex={1}
							nested={true}
						/>

						<div className="price-total">
							<Price {...product} suffix="kr ferdig montert" />
						</div>

						{product?.paymentMethods?.includes(
							'Avbetaling (3 år)'
						) && (
							<div className="price-monthly">
								<p>
									Avbetaling: {getMonthlyPrice(product)}{' '}
									kr/mnd for strømkunder av NTE.
								</p>
								<BlankButton href="#avbetaling">
									<span>Les mer om avbetaling</span>
									<IconArrowDown width="12" height="17" />
								</BlankButton>
							</div>
						)}

						<BuyButton product={product} stretch={true} />

						{(product?.priceDesc ||
							product?.cheapestPriceLast30days) && (
							<p className="help-text">
								{product?.cheapestPriceLast30days
									? `${product?.cheapestPriceLast30days}. `
									: ''}
								{product?.priceDesc}
							</p>
						)}
					</TextWrap>
				</MaxWidthWrap>
			</Spacing>
		</section>
	);
}

const Image = ({ product, images, sliderThumbnails = true }) => {
	if (!images?.length > 0)
		return (
			<img
				src={DefaultImage}
				alt="Dette produktet mangler bilde for øyeblikket"
			/>
		);

	return (
		<SlickSlider
			layout="default"
			images={images}
			dots={!sliderThumbnails}
			autoplay={false}
			fade={true}
			aria-label={`Produktbilder av ${product?.smallTitle} - ${product?.title}`}
			{...(sliderThumbnails && {
				thumbnails: { show: true, count: 4 },
				fullscreenButton: { show: true, position: 'left' },
			})}
		/>
	);
};
