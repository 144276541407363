import React from 'react';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby';

import { track } from 'context/AnalyticsProvider';
import { calculateDownPaymentAmount, priceFormatting } from 'libs/content';
import Button from 'components/forms/Button';

//#region

const Wrap = styled.div`
	.price-original {
		color: ${p => p.theme.colors.grey900};
		margin: 0;
	}
	.price-sale {
		color: ${p => p.theme.colors.grey900};
		margin: 0;
	}

	&.on-sale {
		.price-original {
			text-decoration: line-through;
			margin-right: 7.5px;
			color: ${p => p.theme.colors.grey900};
			opacity: 0.3;
			margin-bottom: 15px;
			${p =>
				p.theme.media.XSmallOnly(css`
					display: block;
				`)}
		}
	}
`;

const NotForSale = styled.p`
	font-weight: 500;
	font-size: 22px;
	line-height: 32px;
	margin: 0;
`;

//#endregion

export default function Price({ suffix = '', ...props }) {
	if (!props?.price && !props?.campaignPrice) {
		return (
			<NotForSale>
				Dette produktet er ikke mulig å bestille for øyeblikket.
			</NotForSale>
		);
	}

	const originalPrice = priceFormatting(props?.price, 'NOK', 'en-GB', false);

	const onSale = !!props?.campaignPrice;

	if (!onSale && props?.price) {
		return (
			<Wrap>
				<span className="price-original">
					{originalPrice}
					{suffix}
				</span>
			</Wrap>
		);
	}

	return (
		<Wrap className="on-sale">
			<span className="price-original">{originalPrice}</span>
			{props?.campaignPrice && (
				<span className="price-sale">
					{props?.campaignPrice &&
						priceFormatting(
							props?.campaignPrice,
							'NOK',
							'en-GB',
							false
						)}{' '}
				</span>
			)}
			{suffix}
		</Wrap>
	);
}

export function BuyButton({ product, stretch = false }) {
	if (!product) return null;

	return (
		<Button
			aria-label={`Gå til bestilling for ${product?.title}`}
			data-cy="continue-btn"
			stretch={stretch}
			title={'Bestill nå'}
			onClick={() => {
				track('Checkout Started', {
					name: product?.title,
					label: product?.title,
					product_id: product?.id,
					category: 'Produkter',
					value: product?.price || 0,
					quantity: 1,
					currency: 'NOK',
				});
				navigate(`/produkter/bestill/?produkt=${product?.slug}`);
			}}>
			Bestill nå
		</Button>
	);
}

export function getMonthlyPrice(product) {
	if (!product || !product.paymentMethods?.includes('Avbetaling (3 år)'))
		return;

	const finalProduct = product;
	const finalPrice = finalProduct.campaignPrice || finalProduct.price;

	return calculateDownPaymentAmount(finalPrice, 36);
}
