import React from 'react';
import styled from 'styled-components';
import { useHits, useInstantSearch } from 'react-instantsearch';

import { fadeIn } from 'libs/animations';
import { GridWrapper } from 'parts/product-columns/ProductWrapper';
import MaxWidth from 'layouts/max-width';
import ProductItem from 'parts/product-grid/ProductItem';
import Pagination from 'components/algolia/Pagination';

const NoResults = styled(MaxWidth)`
	flex: 1 1 100%;
	margin-top: 15px;
	text-align: left;
	animation-name: ${fadeIn};
	animation-duration: 500ms;
	animation-fill-mode: forwards;
	@media (min-width: 1300px) {
		flex: 0 0 calc(80% - 20px);
		padding-left: 20px;
	}
`;

const PaginationWrap = styled.div`
	max-width: 100%;
	margin-top: 20px;
	@media (min-width: 1300px) {
		max-width: calc(80% + 20px);
		margin-left: auto;
		margin-top: 30px;
	}
`;

export default function SearchHits() {
	const { hits } = useHits();
	const { status } = useInstantSearch();

	const products = hits?.map(hit => {
		if (!hit?.bilde?.src) return hit;
		const src = hit.bilde.src;
		hit.bilde = { ...hit.bilde, url: src };
		return hit;
	});

	if (products?.length === 0 && !['stalled', 'loading']?.includes(status)) {
		return (
			<NoResults>
				<p>Vi fant ingen produkter med din filtrering.</p>
			</NoResults>
		);
	}

	return (
		<div>
			<GridWrapper
				perrow={products?.length < 3 ? products?.length : 3}
				total={products?.length}
				id="search-product-results-list"
				data-cy="search-product-results-list">
				{products.map((product, i) => {
					return (
						<ProductItem
							key={product?.id + '-' + i}
							product={product}
							perrow={products?.length < 3 ? products?.length : 3}
							headinglevel="h3"
						/>
					);
				})}
			</GridWrapper>
			<PaginationWrap>
				<Pagination
					padding={5}
					navAriaLabel="Paginasjon for produkter"
				/>
			</PaginationWrap>
		</div>
	);
}
