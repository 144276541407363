import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilterList } from '@fortawesome/pro-regular-svg-icons/faFilterList';
import { AnimatePresence, motion } from 'framer-motion';

import CustomSearchBox from 'components/algolia/SearchBox';
import RefinementDropdown from 'components/algolia/RefinementDropdown';
import ScreenReaderText from 'components/screen-reader-text';

import useWindow from 'context/useWindow';
import BlankButton from 'components/forms/BlankButton';

const ToggleFiltersBtn = styled(BlankButton)`
	margin: 0;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	font-size: 16px;
	line-height: 26px;
	font-weight: 500;
	&:hover {
		svg {
			fill: ${p => p.theme.colors.blue800} !important;
		}
	}
	${p =>
		p.theme.media.medium(css`
			display: none;
		`)}
	svg {
		margin-right: 10px;
	}
`;

const Wrap = styled.div`
	margin-bottom: 20px;
`;

const Fields = styled.div`
	display: flex;
	gap: 20px;
	${p =>
		p.theme.media.smallOnly(css`
			flex-direction: column;
			gap: 15px;
			padding-top: 20px;
		`)}
	> div {
		flex: 1;
	}
`;

export default function ProductFilters() {
	const [showFilters, setShowFilters] = useState(true);
	const [searching, setSearching] = useState(false);

	const toggleBtnLabel = showFilters ? 'Skjul filtrering' : 'Se filtrering';

	return (
		<Wrap id="product-archive-filters">
			<ToggleFiltersBtn
				aria-expanded={showFilters}
				aria-controls="product-archive-filters"
				aria-label={toggleBtnLabel}
				onClick={() => {
					setShowFilters(!showFilters);
				}}
				title={toggleBtnLabel}>
				<FontAwesomeIcon icon={faFilterList} />

				<span>
					{showFilters ? 'Skjul filtrering' : 'Filtrer produkter'}
				</span>
			</ToggleFiltersBtn>

			<SlideUpDown isExpanded={showFilters}>
				<div>
					<ScreenReaderText OuterWrapper="legend">
						Søk eller velg kategori for å finne spesifikke produkt
					</ScreenReaderText>
					<Fields>
						<div>
							<CustomSearchBox
								placeholder="Søk etter produkt"
								searching={searching}
								setSearching={setSearching}
								location="Product Archive"
							/>
						</div>

						<div>
							<RefinementDropdown
								id="product-category-checkboxes"
								placeholder="Produktkategorier"
								label="Produktkategorier"
								attribute="category"
							/>
						</div>
					</Fields>
				</div>
			</SlideUpDown>
		</Wrap>
	);
}

function SlideUpDown({ isExpanded = false, openHeight = 'auto', children }) {
	const { windowWidth } = useWindow();

	if (windowWidth >= 767) return children;

	return (
		<AnimatePresence initial={false}>
			<motion.div
				initial="collapsed"
				animate={isExpanded ? 'open' : 'collapsed'}
				exit="collapsed"
				variants={{
					open: { height: openHeight },
					collapsed: { height: 0 },
				}}
				transition={{
					duration: 0.25,
				}}>
				{children}
			</motion.div>
		</AnimatePresence>
	);
}
