import React from 'react';
import styled, { css } from 'styled-components';

import { AnimationWrap } from 'libs/animations';
import HighlightedImg from 'images/icons/featured.svg';
import ScreenReaderText from 'components/screen-reader-text';

const Wrapper = styled(AnimationWrap)`
	position: absolute;
	margin: 0 !important;
	z-index: 2;

	// Highlighted badge
	${p =>
		p.variant === 'highlighted' &&
		css`
			top: -20px;
			${p =>
				p.position === 'left' &&
				css`
					left: 10px;
					right: auto;
				`}

			${p =>
				p.position === 'right' &&
				css`
					right: 10px;
					left: auto;
				`}
		`}

	// News badge
	${p =>
		p.variant === 'news' &&
		css`
			top: -70px !important;
			${p =>
				p.theme.media.smallOnly(css`
					top: -50px !important;
				`)}
			${p =>
				p.position === 'left' &&
				css`
					left: -30px;
					right: auto;
				`}

			${p =>
				p.position === 'right' &&
				css`
					right: -30px;
					left: auto;
				`}
		`}

	// Default badge (skewed badge with text)
	${p =>
		p.variant === 'default' &&
		css`
			width: 250px;
			height: 250px;
			top: 60px;
			${p =>
				p.theme.media.smallOnly(css`
					width: 150px;
					height: 150px;
					top: 30px;
				`)}

			${p =>
				p.position === 'left' &&
				css`
					left: -60px;
				`}

			${p =>
				p.position === 'right' &&
				css`
					right: -60px;
				`}
		`}
`;

// Corner badge
const CornerBadge = styled.div`
	span {
		position: absolute;
		display: block;
		padding: 6px 0;
		width: 250px;
		background-color: ${p => p.theme.colors.green700};
		color: ${p => p.theme.colors.green100};
		text-transform: uppercase;
		text-align: center;
		font-weight: 400;
		z-index: 2;
		font-size: 14px;
		line-height: 22px;
		${p =>
			p.theme.media.smallOnly(css`
				width: 200px;
			`)}
	}

	// "Kampanje"-badge
	${p =>
		p.text === 'Kampanje' &&
		css`
			span {
				width: 220px;
				top: -30px !important;
				${p =>
					p.theme.media.smallOnly(css`
						width: 200px;
						top: 0 !important;
					`)}

				// Left position
				${p =>
					p.position === 'left' &&
					css`
						left: 10px !important;
					`}

				// Right position
				${p =>
					p.position === 'right' &&
					css`
						right: 10px !important;
					`}
			}
		`}

	// Left position
	${p =>
		p.position === 'left' &&
		css`
			span {
				top: -15px;
				left: -5px;
				transform: rotate(-45deg);
				${p =>
					p.theme.media.smallOnly(css`
						top: 5px;
						left: 15px;
					`)}
			}
		`}

	// Right position
	${p =>
		p.position === 'right' &&
		css`
			span {
				top: -15px;
				right: -5px;
				transform: rotate(45deg);
				${p =>
					p.theme.media.smallOnly(css`
						top: 5px;
						right: 15px;
					`)}
			}
		`}
`;

// News badge
const NewsBadge = styled.div`
	width: fit-content;
	text-align: center;
	transform: rotate(40deg);
	svg {
		display: block;
		width: 100px;
		height: 100px;
		${p =>
			p.theme.media.XSmallOnly(css`
				width: 70px;
				height: 70px;
			`)}
		${p =>
			p.theme.media.large(css`
				width: 150px;
				height: 150px;
			`)}
		.svg-text {
			fill: ${p =>
				(p.bg === 'blue800' && p.theme.colors.blue900) ||
				p.theme.colors.white};
		}
		.svg-bg {
			fill: ${p =>
				(p.bg === 'coral100' && p.theme.colors.coral700) ||
				(p.bg === 'blue800' && p.theme.colors.blue400) ||
				p.theme.colors.blue600};
		}
	}
`;

/**
 * A component for displaying different badge variants.
 * @param {Object} props - The properties for the badge.
 * @param {string} props.variant - The badge variant (default, highlighted, news).
 * @param {string} props.text - The text for the badge.
 * @param {string} props.position - The position for the badge.
 * @returns {JSX.Element} The badge variant component.
 */
export default function Badge({
	variant = 'default',
	text = '',
	position = 'left',
	transitions = false,
	...props
}) {
	position =
		(['highlighted', 'news'].includes(variant) && 'right') || position;
	return (
		<Wrapper
			doanimate={transitions}
			variant={variant}
			position={position}
			direction="in"
			className="badge"
			{...props}>
			<BadgeVariant
				variant={variant}
				text={text}
				position={position}
				{...props}
			/>
		</Wrapper>
	);
}

/**
 * A component for displaying different badge variants.
 * @param {Object} props - The properties for the badge.
 * @param {string} props.variant - The badge variant.
 * @param {string} props.text - The text for the badge.
 * @returns {JSX.Element} The badge variant component.
 */
function BadgeVariant({ variant = 'badge', text = '', ...props }) {
	if (!variant) return null;

	if (variant === 'highlighted') {
		return (
			<div {...props}>
				<img src={HighlightedImg} alt={text || 'Fremhevet produkt'} />
			</div>
		);
	}

	if (variant === 'news') {
		return (
			<NewsBadge {...props}>
				<svg
					viewBox="0 0 205.47 198.02"
					width="205.47"
					height="198.02"
					xmlns="http://www.w3.org/2000/svg">
					<path
						className="svg-bg"
						d="m114.47 2.72c12.96-6.33 28.6-1.25 35.36 11.49 3.87 7.28 10.88 12.38 19 13.8 14.2 2.49 23.87 15.8 21.85 30.08-1.15 8.17 1.52 16.41 7.26 22.33 10.03 10.36 10.03 26.81 0 37.18-5.73 5.93-8.41 14.17-7.26 22.33 2.02 14.28-7.65 27.59-21.85 30.08-8.12 1.42-15.13 6.52-19 13.8-6.76 12.74-22.4 17.82-35.36 11.49-7.41-3.62-16.07-3.62-23.48 0-12.96 6.33-28.6 1.25-35.36-11.49-3.87-7.28-10.88-12.38-19-13.8-14.2-2.49-23.87-15.8-21.85-30.08 1.15-8.17-1.52-16.41-7.26-22.33-10.03-10.36-10.03-26.81 0-37.18 5.73-5.93 8.41-14.17 7.26-22.33-2.02-14.28 7.65-27.59 21.85-30.08 8.12-1.42 15.13-6.52 19-13.8 6.76-12.74 22.4-17.82 35.36-11.49 7.41 3.62 16.07 3.62 23.48 0z"
						fill="#ffffff"
					/>
					<g className="svg-text" fill="#0079AD">
						<path d="m72.62 131.67-6.22 3.04-21.81-13.95 9.7 19.86-6.22 3.04-14.74-30.19 6.22-3.04 21.85 13.98-9.72-19.9 6.2-3.03z" />
						<path d="m79.78 107.63-.36-16.67 6.8-3.32-.52 24.08 5.35 10.95-6.32 3.09-5.34-10.95-19.31-14.4 6.82-3.33z" />
						<path d="m128.29 104.49-6.22 3.04-6.32-12.94-12.13 5.92 6.32 12.94-6.22 3.04-14.74-30.19 6.22-3.04 5.97 12.23 12.13-5.92-5.97-12.23 6.22-3.04z" />
						<path d="m145.5 79.88-11.94 5.83 3.95 8.09 14.02-6.84 2.44 5-20.24 9.88-14.74-30.19 20.19-9.86 2.46 5.04-13.97 6.82 3.51 7.19 11.94-5.83z" />
						<path d="m168.47 53.72-9.25 4.52 12.28 25.15-6.22 3.04-12.28-25.15-9.12 4.45-2.46-5.04 24.59-12.01z" />
					</g>
				</svg>
				<ScreenReaderText>Nyhet</ScreenReaderText>
			</NewsBadge>
		);
	}

	if (!text) return;

	return (
		<CornerBadge {...props} text={text}>
			<span>{text}</span>
		</CornerBadge>
	);
}

/**
 * Get the badge variant based on the product properties.
 * @param {Object} props - The properties for the badge.
 * @param {string} props.highlighted - Whether the product is highlighted.
 * @param {string} props.clientfavorite - Whether the product is a client favorite.
 * @param {string} props.customBadge - Custom text for the badge.
 * @param {string} props.newproduct - Whether the product is new.
 * @param {boolean} props.campaignPrice - Whether the product has a campaign price.
 * @returns {string} The badge variant.
 */
export function getBadgeVariant({ ...props }) {
	const variants = [
		{
			condition: props?.highlighted === 'true',
			value: 'highlighted',
			text: props?.text || 'Fremhevet produkt',
		},
		{
			condition: props?.clientfavorite === 'true',
			value: 'highlighted',
			text: props?.text || 'Kundenes favoritt',
		},
		{
			condition: !!props?.customBadge,
			value: 'default',
			text: props?.customBadge,
		},
		{
			condition: props?.newproduct === 'true',
			value: 'news',
			text: 'Nyhet',
		},
		{
			condition: !!props?.campaignPrice,
			value: 'default',
			text: 'Kampanje',
		},
	];

	const result = variants.find(v => v?.condition);

	return (
		(!!result && {
			text: result?.text,
			variant: result?.value,
		}) ||
		null
	);
}
